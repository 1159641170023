import { Router } from 'router';

import { RootProvider } from 'providers/RootProvider';

const App = () => (
  <RootProvider>
    <Router />
  </RootProvider>
);

export default App;
