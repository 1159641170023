import { ReactNode, cloneElement } from 'react';

const nest = (children: any, Component: any) =>
  cloneElement(Component, {}, children);

export const MultiProvider = ({
  children,
  providers,
}: {
  children: ReactNode;
  providers: any[];
}) => <>{providers.reduceRight(nest, children)}</>;
