import 'react-loading-skeleton/dist/skeleton.css';

import { globalCss, globalStyles } from '@resiliantinc/design-system';

const localGlobalStyles = globalCss({
  'html, body': { height: '100%' },
  'html *': { boxSizing: 'border-box' },
  '.drift-frame-chat, .drift-frame-controller, .drift-conductor-item': {
    pointerEvents: 'visible !important',
  },
  a: {
    color: '$sky600',
    textDecoration: 'underline',
  },
});

export const GlobalStyles = () => {
  globalStyles();
  localGlobalStyles();

  return null;
};
