import { IConfig } from './config.interface';

const ENV = process.env;

const config: IConfig = {
  API_BASE_URL: ENV.REACT_APP_API_BASE_URL as string,
  ENVIRONMENT: ENV.REACT_APP_ENVIRONMENT as string,
};

export default config;
